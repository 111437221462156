/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Checkbox, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { addNewClient, editClient, getClients } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { format } from "date-fns";
import { toast } from "react-toastify";
import './Clients.css'

const Clients = () => {
    const {state} = useAuth();
    const [clients, setClients] = useState<any>([]);

    const handleStatusChange = (id: number) => {
        setClients((prevRows: any[]) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, status: !row.status } : row
            )
        );
    };

    const handleAddRow = () => {
        const newRow = {
            id: clients.length + 1,
            companyName: ``,
            status: true,
        };
        setClients((prevRows: any) => [newRow, ...prevRows]);
    };

    const handleChange = (id: any, field: any, newValue: any) => {
        const updatedRows = clients.map((row: any) =>
          row.id === id ? { ...row, [field]: newValue } : row
        );
        setClients(updatedRows);
    };

    const getAllClients = async () => {
        const response = await getClients(state.user?.token);
        if(response){
            setClients(response);
        }
    }

    useEffect(() => {
        getAllClients()
    }, [])

    const columns: any = [
        {
            field: "companyName",
            headerName: "Clients",
            flex: 1,
            renderCell: (params: any) => (
                <TextField
                    size="small"
                    value={params.row.companyName}
                    style={{margin: 5}}
                    fullWidth
                    onChange={(event: any) => {
                        handleChange(params.id, "companyName", event.target.value);
                    }}
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                          if (event.key === ' ') {
                            event.stopPropagation();
                          }
                        },
                    }}
                />
            ),
        },
        {
            field: "status",
            headerName: "STATUS",
            flex: 0.4,
            renderCell: (params: any) => (
                <Checkbox
                    checked={params.row.status}
                    onChange={() => handleStatusChange(params.row.id)}
                    style={{color: '#ffa970'}}
                />
            ),
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 0.4,
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.createdDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "SAVE",
            headerName: "Save",
            flex: 0.4,
            renderCell: (params: any) => (
                <Button variant="outlined" onClick={()=> handleCellEditCommit(params)}>Save</Button>
            )
        },
    ];

    const handleCellEditCommit = async (params: any) => {
        if(typeof params.row.id === 'number') {
            const response = await addNewClient(state.user?.token, params.row.companyName)
            if(response) {
                toast.success(response.data);
            }else {
                toast.error('Something went wrong!')
            }
        } else {
            const response = await editClient(state.user?.token, {
                id: params.row.id,
                companyName: params.row.companyName,
                status: params.row.status
            })
            if(response === 403) {
                toast.warn('You are not authorized!');
            }else if (response) {
                toast.success(response.data);
            }
        }
    };

    const Toolbar = () => {
        return (
            <div>
                <GridToolbarFilterButton />
            </div>
        );
    };

    return (
        <div style={{ overflow: 'hidden'}}>
            <div className="add-button">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddRow}
                    style={{  
                        marginRight: '3%',
                        borderColor: "#ffa970",
                        backgroundColor: "white",
                        color: "#ffa970"
                    }}
                >
                    Add New Client
                </Button>
            </div>
            <Box
                sx={{
                    width: "60%",
                    height: '840px',
                    margin: "0 auto",
                    mt: -4,
                    "@media (max-width: 800px)": {
                        overflowX: "auto",
                        margin: 1,
                        minWidth: "95%",
                    },
                }}
            >
                <DataGrid
                    rows={clients}
                    columns={columns}
                    slots={{ toolbar: Toolbar }}
                    disableRowSelectionOnClick
                    sx={{
                        "@media (max-width: 700px)": {
                            minWidth: "100%",
                        },
                        "& .MuiDataGrid-columnHeader": {
                            backgroundColor: "#ffa970",
                            color: "#000",
                            fontWeight: "bold",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        "& .disable-columns": {
                            backgroundColor: "#f5f5f5",
                        },
                    }}
                />
            </Box>
        </div>
    );
};

export default Clients;