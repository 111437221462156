/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import {
  addNewWorkerToShift,
  changeWorkerRowColorIdentifier,
  fatigueCheck,
  GetJobWithWorkersModel,
  getPaymentMethod,
  getSignedInOptions,
  getWorkersFromJob,
  GetWorkersSuggestions,
  getWorkRolesSuggestions,
  removeWorkersFromJob,
  sendPushNotifications,
  updateJobWorkerRows,
} from "../../../../api/apiCall";
import { useAuth } from "../../../../auth/auth";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faX,
  faEnvelope,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "./Worker.css";

const Workers = ({id, week, year, shiftData, connection}: any) => {
  const {state} = useAuth();
  const [workers, setWorkers] = useState<any>([]);
  const [signedInOptions, setSignedInOptions] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [workRoleSuggestions, setWorkRoleSuggestions] = useState<any>([]);
  const [biggestOrderNumber, setBiggestOrderNumber] = useState<any>();
  const [selectedColor, setSelectedColor] = useState("");
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [addedWorkers, setAddedWorkers] = useState<any>([])
  const typingDelay = 100;
  let typingTimer: any;

  const getBackgroundColor = (signedInValue: number) => {
    switch (signedInValue) {
      case 0:
        return "white";
      case 1:
        return "#ffc0cb";
      case 2:
        return "#90ee90";
      case 3:
        return "#FF0000";
    }
  };

  const handleChange = (id: any, field: any, newValue: any) => {
    const updatedRows = workers.map((row: any) =>
      row.id === id ? { ...row, [field]: newValue } : row
    );
    setWorkers(updatedRows);
  };

  const getSColor = (value: number) => {
    switch (value) {
      case 0:
        return "white";
      case 1:
        return "#90ee90";
      case 2:
        return "red";
      case 3:
        return "#f7de3a";
    }
  }

  const getTColor = (value: number) => {
    switch (value) {
      case 0:
        return "white";
      case 1:
        return "#90ee90";
      case 2:
        return "red";
    }
  }

  const columns: any = [
    {
      field: "orderNumber",
      headerName: "#",
      width: 30,
      filterable: false,
    },
    {
      field: "workRole",
      headerName: "Role",
      width: 250,
      renderCell: (params: any) => (
        <Autocomplete
          size="small"
          freeSolo
          value={params.row.workRole}
          style={{ margin: 5 }}
          options={workRoleSuggestions}
          getOptionLabel={(option: any) => `${option || ""}`}
          onInputChange={(event, newInputValue) => {
            handleChange(params.id, "workRole", newInputValue);
          }}
          onChange={(event, newValue) => {
            handleChange(params.id, "workRole", newValue);
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                onKeyDown: (event) => {
                  if (event.key === ' ') {
                    event.stopPropagation();
                  }
                },
              }}
            />
          }
        />
      ),
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 250,
      renderCell: (params: any) => (
        <Autocomplete
          size="small"
          value={params.row}
          options={suggestions || []}
          disableClearable
          style={{ margin: 5 }}
          getOptionLabel={(option: any) => `${option?.fullName || ""}`}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            handleNameChange(params.id, newValue);
          }}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.fullName}>
              {option.fullName} - {option.pts}
            </ListItem>
          )}
          renderInput={(params) =>
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                onKeyDown: (event) => {
                  if (event.key === ' ') {
                    event.stopPropagation();
                  }
                },
              }}
            />
          }
        />
      ),
    },
    {
      field: "pts",
      headerName: "PTS",
      width: 80,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 120,
    },
    {
      field: "t",
      headerName: "T",
      width: 80,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "60px", backgroundColor: getTColor(params.row.t) }}
          defaultValue={params.row.t}
          onChange={(event, newValue: any) => {
            handleChange(params.id, "t", newValue?.props?.value);
          }}
        >
          <MenuItem value={0} style={{ height: 25 }}></MenuItem>
          <MenuItem
            value={1}
            style={{ backgroundColor: "#90ee90", height: 25 }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </MenuItem>
          <MenuItem value={2} style={{ backgroundColor: "red", height: 25 }}>
            <FontAwesomeIcon icon={faX} />
          </MenuItem>
        </Select>
      ),
    },
    {
      field: "s",
      headerName: "S",
      width: 80,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "60px", backgroundColor: getSColor(params.row.s) }}
          defaultValue={params.row.s}
          onChange={(event, newValue: any) => {
            handleChange(params.id, "s", newValue?.props?.value);
          }}
        >
          <MenuItem value={0} style={{ height: 25 }}></MenuItem>
          <MenuItem
            value={1}
            style={{ backgroundColor: "#90ee90", height: 25 }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </MenuItem>
          <MenuItem value={2} style={{ backgroundColor: "red", height: 25 }}>
            <FontAwesomeIcon icon={faX} />
          </MenuItem>
          <MenuItem
            value={3}
            style={{ backgroundColor: "#f7de3a", height: 25 }}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </MenuItem>
        </Select>
      ),
    },
    {
      field: "h_color",
      headerName: "H",
      width: 110,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "100px", backgroundColor: getBackgroundColor(params.row.h_Color) }}
          defaultValue={params.row.h_Color}
          onChange={(event, newValue: any) => {
            handleChange(params.id, "h_Color", newValue?.props?.value);
          }}
        >
          {signedInOptions &&Array.isArray(signedInOptions)&& signedInOptions.map((options: any) => (
            <MenuItem
              value={options.value}
              style={{
                backgroundColor: getBackgroundColor(options.value),
                height: 25,
              }}
            >
              {options.name === "Neutral" ? "" : options.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "f",
      headerName: "F",
      width: 40,
      editable: false,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Checkbox
          checked={params.row.f}
          style={{color: params.row.f && '#74c365'}}
          onChange={(event, checked) => {
            handleChange(params.id, "f", checked);
          }}
        />
      ),
    },
    {
      field: "d",
      headerName: "D",
      width: 40,
      editable: false,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Checkbox
          checked={params.row.d}
          style={{color: params.row.d && '#74c365'}}
          onChange={(event, checked) => {
            handleChange(params.id, "d", checked);
          }}
        />
      ),
    },
    {
      field: "paymentMethodId",
      headerName: "P",
      width: 120,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "110px" }}
          defaultValue={params.row.paymentMethodId}
          onChange={(event, newValue: any) => {
            const selectedValue = newValue?.props?.value === 1 ? null : newValue?.props?.value;
            handleChange(params.id, "paymentMethodId", selectedValue);
          }}
        >
          <MenuItem value={1} style={{ height: 40 }}>
            No payment method
          </MenuItem>
          {paymentMethods.map((options: any) => (
            <MenuItem
              value={options.id}
              style={{ height: 40 }}
            >
              {options.paymentMethodName}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "paid",
      headerName: "Paid",
      width: 40,
      editable: false,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Checkbox
          checked={params.row.paid}
          style={{color: params.row.paid && '#74c365'}}
          onChange={(event, checked) => {
            handleChange(params.id, "paid", checked);
          }}
        />
      ),
    },
    {
      field: "riskAssessments",
      headerName: "Risk Assessments",
      width: 227,
      headerAlign: "center",
      align: 'center',
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "207px" }}
          defaultValue={params.row.riskAssessments}
          onChange={(event) =>handleChange(params.id, "riskAssessments", event.target.value)}
        >
          <MenuItem value={0} style={{ height: 25 }}></MenuItem>
          <MenuItem
            value={1}
            style={{ backgroundColor: "#f23819", height: 25 }}
          >
            Risk Assessment Needed
          </MenuItem>
          <MenuItem value={2} style={{ backgroundColor: "#f7de3a", height: 25 }}>
            Risk Assessment Waiting Approval
          </MenuItem>
          <MenuItem
            value={3}
            style={{ backgroundColor: "#90ee90", height: 25 }}
          >
            Risk Assessment Approved
          </MenuItem>
        </Select>
      ),
    },
    {
      field: "h",
      headerName: "Actual Hours",
      width: 110,
      headerAlign: "center",
      renderCell: (params: any) => (
        <TextField
          type="number"
          size="small"
          value={params.row.h}
          style={{ width: "90px", height: "5px", margin: 5 }}
          onChange={(event: any) => {
            handleChange(params.id, "h", event.target.value);
          }}
        />
      ),
    },
    {
      field: "hourlyRate",
      headerName: "Hourly Rate",
      width: 110,
      editable: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      editable: true,
    },
  ];

  const getWorkers = async () => {
    const response = await getWorkersFromJob(state.user?.token, id);
    setWorkers(response);
    const orderNumbers: [] = response.map((worker: any) => worker.orderNumber);
    const maxOrderNumber =
      orderNumbers && orderNumbers.length > 0 ? Math.max(...orderNumbers) : 0;
    setBiggestOrderNumber(maxOrderNumber);
  };

  const addNewWorker = async () => {
    setLoading(true)
    try {
      const response = await addNewWorkerToShift(
        state.user?.token,
        id,
        biggestOrderNumber + 1
      );
      
      if (response) {
        const response = await GetJobWithWorkersModel(state.user?.token, id);
        await connection.invoke('EditJob', `${week}-${year}`, response);
        toast.success("Added new worker successfully")
        getWorkers();
      }else {
        toast.error("Something went wrong!")
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  const updateWorkers = async () => {
    try {
      const response = await updateJobWorkerRows(state.user?.token, workers);
      await changeWorkerRowColorIdentifier(
        state.user?.token,
        selectedRows,
        selectedColor
      );
      if(response) {
        const response = await GetJobWithWorkersModel(state.user?.token, id);
        const sendNotifications = await sendPushNotifications(state.user?.token, id, addedWorkers);
        setAddedWorkers([])
        await connection.invoke('EditJob', `${week}-${year}`, response);
      }
      getWorkers();
      if (response?.data) {
        toast.success('Updated Workers successfully')
        getWorkers();
      }else{
        toast.error('Something went wrong!')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Toolbar = () => {
    return (
      <div>
        <GridToolbarFilterButton />
      </div>
    );
  };

  const shiftTypeRates = (shiftType: any, newName: any) => {
    switch (shiftType) {
      case 0:
        return newName?.basicRates?.dayRate ?? 0;
      case 1:
        return newName?.basicRates?.nightRate ?? 0;
      case 2:
        return newName?.basicRates?.weekendRate ?? 0;
      default:
        return null;
    }
  };

  const modifiedColumns = columns.map((col: any) => ({
    ...col,
    cellClassName: (params: any) => getBackColor(params.row.colorIdentifier),
  }));

  const handleNameChange = async (id: any, newName: any) => {
    const response = await fatigueCheck(state.user?.token, newName.userId, id);

    setAddedWorkers((prevWorkers: any) => {
      const isWorkerExist = workers.some((worker: any) => worker.userId === newName.userId);
      if (isWorkerExist) {
        return prevWorkers;
      }

      const isWorkerreplaced = workers.find((worker: any) => worker.id === id);
    
      if (isWorkerreplaced) {
        const workerId = isWorkerreplaced.userId;
    
        const workerIndex = prevWorkers.findIndex((w: any) => w === workerId);

        if (workerIndex !== -1) {
          const updatedWorkers = [...prevWorkers];
          updatedWorkers[workerIndex] = newName.userId;
          return updatedWorkers;
        }
      } else {
        console.log("No worker found with the given id.");
      }

      return [...prevWorkers, newName.userId];
    });

    if(response.fatigued){
      toast.error(response.description,{autoClose: 5000})
    }

    const updatedRows = workers.map((row: any) =>
    row.id === id
      ? {
          ...row,
          userId: newName.userId,
          fullName: newName.fullName,
          pts: newName.pts,
          phoneNumber: newName.phoneNumber,
          hourlyRate: shiftTypeRates(shiftData.shiftType, newName),
          colorIdentifier: response.colorIdentifier
        }
      : row);
    setWorkers(updatedRows);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await GetWorkersSuggestions(
          state.user?.token,
          inputValue
        );

        if (response) {
          setSuggestions(response.data);
        } else {
          console.error("Error fetching suggestions");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (inputValue) {
      typingTimer = setTimeout(fetchSuggestions, typingDelay);
    }

    return () => clearTimeout(typingTimer);
  }, [inputValue]);

  const handleCellEditCommit = (params: any) => {
    const updatedRows = workers.map((row: any) =>
      row.id === params.id
        ? {
            ...row,
            workRole: params.workRole,
            notes: params.notes,
            hourlyRate: params.hourlyRate,
          }
        : row
    );
    setWorkers(updatedRows);
  };

  const handleRowSelection = (newSelectionModel: any) => {
    setSelectedRows(newSelectionModel);
  };

  const deleteWorker = async () => {
    const response = await removeWorkersFromJob(state.user?.token, selectedRows);
    if (response) {
      const response = await GetJobWithWorkersModel(state.user?.token, id);
      await connection.invoke('EditJob', `${week}-${year}`, response);
      toast.error('Deleted worker successfully.')
      setDeleteModalIsOpen(false);
    }
  };

  const handleChangeColor = (event: any) => {
    setSelectedColor(event.target.value);
    const updatedRows = workers.map((row: any) =>
      selectedRows.includes(row.id)
        ? { ...row, colorIdentifier: event.target.value }
        : row
    );
    setWorkers(updatedRows);
  };

  const getBackColor = (color: any) => {
    switch (color) {
      case "red":
        return "red";
      case "lightgreen":
        return "lightgreen";
      case "yellow":
        return "yellow";
      case "ember":
        return "ember"
    }
  };

  useEffect(() => {
    getWorkers();
    getSignedInOptions(state.user?.token).then(setSignedInOptions)
    getPaymentMethod(state.user?.token).then(setPaymentMethods);
    getWorkRolesSuggestions(state.user?.token).then(setWorkRoleSuggestions);
  }, []);

  useEffect(() => {
    if(connection) {
      connection.on('EditJob', (jobModel: any) => {
        if(id === jobModel.job.id) {
          setWorkers(jobModel.workers)
          getWorkers();
        }
      })

      return () => {
        connection.off('EditJob');
      }
    }
  }, [connection])

  return (
    <>
      <Modal
        title={`Are you sure you want to delete?`}
        centered
        open={deleteModalIsOpen}
        okButtonProps={{ danger: true }}
        cancelText={"NO"}
        okText={"YES"}
        onOk={deleteWorker}
        onCancel={() => setDeleteModalIsOpen(false)}
      />
      <Box sx={{ height: 578, width: "100%" }}>
        <DataGrid
          rows={workers}
          columns={modifiedColumns}
          slots={{ toolbar: Toolbar }}
          checkboxSelection
          disableRowSelectionOnClick
          processRowUpdate={handleCellEditCommit}
          onRowSelectionModelChange={handleRowSelection}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "",
              color: "",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .red": {
              backgroundColor: "red",
            },
            "& .lightgreen": {
              backgroundColor: "lightgreen",
            },
            "& .yellow": {
              backgroundColor: "yellow",
            },
            "& .ember" : {
              backgroundColor: '#f23819'
            }
          }}
        />
      </Box>
      <div className="center-div">
        <div>
          <Button
            variant="contained"
            onClick={updateWorkers}
            style={{
              margin: "20px",
              color: "white",
              borderColor: "black",
              height: "35px",
              boxShadow: "none",
            }}
          >
            Save Changes
          </Button>
          <Button
            variant="outlined"
            onClick={addNewWorker}
            startIcon={loading ? '' : <FontAwesomeIcon icon={faPlus} size={"3x"} color="#1976d2" />}
            disabled={loading}
            size="large"
            style={{
              width: '250px',
              borderColor: "#1976d2",
              color: "black",
              height: "35px",
              alignItems: 'center'
            }}
          >
            {loading ? <CircularProgress size={30} style={{ color: '#1976d2', fontSize: 25 }} /> : 'Add New Worker'}
          </Button>
        </div>
        <div>
          {selectedRows && selectedRows.length > 0 ? (
            <>
              Change Color:
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                defaultValue="white"
                style={{ height: "35px", width: "120px", margin: "20px" }}
                value={selectedColor}
                onChange={handleChangeColor}
              >
                <MenuItem value={"white"} style={{ height: 25 }}>
                  {" "}
                  No Color
                </MenuItem>
                <MenuItem
                  value={"lightgreen"}
                  style={{ backgroundColor: "#90ee90", height: 25 }}
                >
                  Green
                </MenuItem>
                <MenuItem
                  value={"red"}
                  style={{ backgroundColor: "#ff0000", height: 25 }}
                >
                  Red
                </MenuItem>
                <MenuItem
                  value={"yellow"}
                  style={{ backgroundColor: "#f7de3a", height: 25 }}
                >
                  Yellow
                </MenuItem>
              </Select>
              <Button
                onClick={() => setDeleteModalIsOpen(true)}
                style={{
                  margin: "20px",
                  color: "white",
                  backgroundColor: "red",
                  height: "35px",
                  boxShadow: "none",
                }}
              >
                Delete
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Workers;
