import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMap, Tooltip } from "react-leaflet";
import './LiveTracker.css'
import { Button } from "antd";
import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { useAuth } from "../../../auth/auth";
import L from "leaflet";
import Modal from 'react-modal';

const MapController = ({ coordinates, zoom }: { coordinates: [number, number], zoom: number }) => {
    const map = useMap();
    useEffect(() => {
        if (coordinates) {
            map.setView(coordinates, zoom);
        }
    }, [coordinates, map]);
    return null;
};

const customDivIcon = L.divIcon({
    className: 'pulse',
    html: '<span class="pulse"></span>',
    iconSize: [20, 20]
});

const LiveTracker = () => {
    const {state} = useAuth()
    const [coordinates, setCoordinates] = useState<any>([51.505, -0.09]);
    const [workers, setWorkers] = useState<any>([]);
    const [zoom, setZoom] = useState<number>(9);
    const [open, setOpen] = useState(false);

    const closeModal = () => {
      setOpen(false);
    };

    const getWorkerCoordinates = (latitude: any, longitude: any) => {
        setCoordinates([latitude, longitude]);
        setZoom(12);
    }

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('https://api.pace-is.co.uk/GeoLocationHub', {
                accessTokenFactory: () => state.user?.token ? state.user.token : '',
                headers: {
                    'Authorization': `Bearer ${state.user?.token}`,
                },
            })
            .withAutomaticReconnect([10000, 30000])
            .configureLogging(LogLevel.Information)
            .build();

        const startConnection = async () => {
            try {
                await newConnection.start();
                newConnection.invoke('JoinAdministrationGroup');
                
                console.log("SignalR Connected")
            } catch (err) {
                console.log("Error connecting to SignalR", err);
                setTimeout(startConnection, 2000);
            }
        };

        newConnection.onclose(async (error) => {
            console.error("Connection closed unexpectedly", error);
            if (error) {
                console.log("Attempting to reconnect...");
                setTimeout(startConnection, 2000);
            }
        });

        newConnection.onreconnecting((error) => {
            console.warn("Connection lost. Reconnecting...", error);
        });

        newConnection.onreconnected((connectionId) => {
            console.log("SignalR reconnected. Connection ID:", connectionId);
        });

        newConnection.serverTimeoutInMilliseconds = 60000;

        startConnection();

        newConnection.on('LoadWorkerToMap', (newWorker: any) => {
            setWorkers((prevWorkers: any[]) => {
                const isWorkerExist = prevWorkers.some(worker => worker.id === newWorker.id);
            
                if (isWorkerExist) {
                  return prevWorkers.map(worker => 
                    worker.id === newWorker.id 
                    ? { 
                        ...worker, 
                        longitude: newWorker.longitude,
                        latitude: newWorker.latitude,
                        lastUpdate: Date.now(),
                      }
                    : worker
                  );
                }
            
                return [...prevWorkers, newWorker];
            });
        });

        const removeWorker = setInterval(() => {
            setWorkers((prevWorkers: any) =>
                prevWorkers.filter((worker: any) => Date.now() - worker.lastUpdate < 10000)
            );
        }, 10000);

        return () => {
            if (newConnection.state === HubConnectionState.Connected) {
                newConnection.stop();
                clearInterval(removeWorker)
                console.log("SignalR Disconnected");
            }
        };
    }, []);

    return (
        <div>
            <Modal
                isOpen={open}
                onRequestClose={() => {setOpen(false)}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className='modal-live'
                style={{
                    overlay: {
                        zIndex: 10000,
                        backgroundColor: 'transparent',
                        transition: 'none',
                        width: '100%',
                        msTransformOrigin: 'right'
                    }
                }}
            >
                <div className="modal-header">
                    <h3>Tracking:</h3>
                    <button onClick={closeModal} className="close-modal-btn">&times;</button>
                </div>
                <div className="modal-body">
                    {workers.map((worker: any) => (
                        <div
                            key={worker.id}
                            className="worker-details"
                            onClick={() => getWorkerCoordinates(worker.latitude, worker.longitude)}
                        >
                            <p><strong>Name:</strong> {worker.name}</p>
                            <p><strong>PTS:</strong> {worker.pts}</p>
                        </div>
                    ))}
                </div>
            </Modal>
            <MapContainer center={coordinates} zoom={9} style={{ height: '91vh', width: '100%', zIndex: 1 }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Button
                    className="open-button"
                    onClick={() => setOpen(true)}
                >
                    <FontAwesomeIcon icon={faUserGroup} />
                </Button>
                {workers.map((worker: any) => (
                    <Marker
                        key={worker.id}
                        icon={customDivIcon}
                        position={[worker.latitude, worker.longitude]}
                    >
                        <Tooltip permanent={true} direction="top">
                            <div style={{ backgroundColor: 'white', fontWeight: 600, textAlign: 'center'}}>{worker.name} <br/> {worker.pts}</div>
                        </Tooltip>
                    </Marker>
                ))}
                <MapController coordinates={coordinates} zoom={zoom}/>
            </MapContainer>
        </div>
    )
}

export default LiveTracker;
