import React from "react";
import icon from '../../../../assets/map-regions.svg'
import './RegionsMap.css'
const RegionsMap = () => {
    const regions = [
        { name: 'London', workers: 650 },
        { name: 'Anglia', workers: 112 },
        { name: 'East_Midlands', workers: 46 },
        { name: 'West_Midlands', workers: 139 },
        { name: 'Wales', workers: 2 },
        { name: 'South_East', workers: 112 },
        { name: 'South_West', workers: 33 },
        { name: 'Yorks_Humber', workers: 100 },
        { name: 'North_West', workers: 151 },
        { name: 'North_East', workers: 135 },
        { name: 'Scotland', workers: 1 },
        { name: 'Northern_Ireland', workers: 0 },
    ];

    return (
        <div style={{display:'flex'}}>
            <img src={icon} alt="Icon" width={950} height={950}/>
            <div className="container-map">
                <h1 className="title">PACE Regions</h1>
                <p className="subtitle">Number of workers on regions</p>
                <div className="region-list">
                    {regions.map((region) => (
                        <div className="region-item" key={region.name}>
                            <span className="region-name">{region.name.replace('_', ' ')}</span>
                            <span className="region-workers">{region.workers}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RegionsMap;
