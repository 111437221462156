import React from "react";
import Regions from "./Regions/Regions";
import WorkRoleSuggestions from "./WorkRole/WorkRole";
import PaceSiteContact from "./PaceSiteContact/PaceSiteContact";
import ClientSiteContact from "./ClientSiteContact/ClientSiteContacts";
import './RTdata.css'

const RTdata = () => {
    return (
        <>
            <div className="main-div">
                <div className="regions-div">
                    <Regions/>
                </div>
                <div className="regions-div">
                    <WorkRoleSuggestions />
                </div>
            </div>
            <div className="main-div">
                <div className="regions-div">
                    <PaceSiteContact />
                </div>
                <div className="regions-div">
                    <ClientSiteContact />
                </div>
            </div>
        </>
    )
}

export default RTdata;
