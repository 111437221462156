import axios from "axios";
import { format } from "date-fns";

const apiUrl = 'https://railresourcing.api.pace-is.co.uk';

export const loginUser = async (payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/home/login`, payload)
        return response
    } catch (error: any) {
        return error?.response?.data.detail;
    }
}

export const getProjects = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Collaborations/GetProjects`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getClients = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Collaborations/GetClients`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getAllClients = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/options/GetClients`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const GetRegions = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetRegions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const addNewShift = async (token: any, payload: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/Resourcing/AddNewJob`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const getWeeklyJobs = async (token: any, week: number, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyJobs?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getEditJobModal = async (token: any, id: string) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetEditJobModel?jobId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const editShift = async (token: any, payload: any) => {
    try{
        const response = await axios.post(`${apiUrl}/Resourcing/EditJob`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response
    }catch (error){
        return error
    }
}

export const editJobGridLocation = async (token: any, payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/EditJobGridLocation`, payload,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const getWorkersFromJob = async (token: any, id: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWorkersFromJob?jobId=${id}`,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const GetWorkersSuggestions = async (token: any, value: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/options/GetWorkersSuggestions?search=${value}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const deleteJob = async (token: any, id: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/Resourcing/DeleteJob?jobId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.log(error)
    }
}

export const getSignedInOptions = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetSignedInOptions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data;
    } catch (error) {
        return error
    }
}

export const getJobStatuses = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetJobStatuses`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data;
    } catch (error) {
        return error
    }
}

export const addNewWorkerToShift = async (token: any, jobId: any, orderNumber: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/AddNewWorkerToJob?jobId=${jobId}&orderNumber=${orderNumber}`, {},
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return error
    }
}

export const updateJobWorkerRows = async (token: any, payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/UpdateJobWorkerRows`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const removeWorkersFromJob = async (token: any, payload: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/Resourcing/RemoveWorkersFromJob`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: payload 
        })
        return response
    } catch(error) {
        console.log(error)
    }
}

export const getClientsPieData = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Statistics/getClientsPieData?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const getJobsStatusPieData = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Statistics/getJobsStatusPieData?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const getClientJobsStatistics = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Statistics/GetClientJobsStatistics?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const getJobsLocations = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/getJobsLocations?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const changeWorkerRowColorIdentifier = async (token: any, JobRowsIds: any, color: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/ChangeWorkerRowColorIdentifier`, {
            JobRowsIds: JobRowsIds,
            Color: color
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const saveJobNotes = async (token: any, jobId: any, notes: any, notesJson: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/SaveJobNotes?jobId=${jobId}&notes=${notes}&notesJson=${notesJson}`, {} ,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const duplicateShift = async (token: any, jobId: any, jobDate: any, rowIndex: any, colIndex: any,week: any, HubGroupName: any ) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/DuplicateJob`, {
            jobId: jobId,
            jobDate: jobDate,
            rowIndex: rowIndex,
            columnIndex: colIndex,
            week: week,
            HubGroupName: HubGroupName
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const getPaceSiteContacts = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetSiteContacts`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getClientSitescontact = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetClientSitesContacts`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getPaymentMethod = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetPaymentMethods`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getWorkRolesSuggestions = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetWorkRolesSuggestions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getWeeklyWorkersData = async (token: any, week: number, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyWorkersData?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch(error) {
        console.log(error)
    }
}

export const getWeeklyOnCallDetails = async (token: any, week: number, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyOnCallDetails?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch(error) {
        console.log(error)
    }
}

export const getWeeklyWorkersList = async (token: any, week: number, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyWorkersList?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getWeeklyJobsDetailsForMap = async (token: any, week: any, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyJobsDetailsForMap?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    }catch(error){
        console.log(error)
    }
}

export const getOrgChartDiagram = async (token: any, week: any, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Statistics/GetOrgChartDiagram?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const fatigueCheck = async (token: any, workerId: any, rowId: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/FatigueCheck?workerId=${workerId}&rowId=${rowId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const GetJobWithWorkersModel = async (token: any, jobId: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetJobWithWorkersModel?jobId=${jobId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const addNewClient = async (token: any, companyName: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/Collaborations/AddNewClient?companyName=${companyName}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const addNewProject = async (token: any, projectName: any, companyId: any, postCode: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/Collaborations/AddNewProject`, {
                ProjectName: projectName,
                CompanyId: companyId,
                PostCode: postCode
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const editProject = async (token: any, payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/api/Collaborations/EditProject`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json'
            }
        })
        return response;
    } catch (error: any) {
        if (error.response) {
            if (error.response.status === 403) {
                return 403
            } else {
                console.log(`Error: ${error.response.status} - ${error.response.statusText}`);
            }
        } else {
            console.log("An unexpected error occurred:", error.message);
        }
        
        return error;
    }
}

export const editClient = async (token: any, payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/api/Collaborations/EditClient`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json'
            }
        })
        return response;
        
    } catch (error: any) {
        if (error.response) {
            if (error.response.status === 403) {
                return 403
            } else {
                console.log(`Error: ${error.response.status} - ${error.response.statusText}`);
            }
        } else {
            console.log("An unexpected error occurred:", error.message);
        }
        
        return error;
    }
}

export const GetAllRegions =async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/ResourcingData/GetRegions`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json'
            }
        })
        return response.data
    }catch (error) {
        console.log(error)
    }
}

export const GetAllWorkRole = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/ResourcingData/GetWorkRoleSuggestions`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json'
            }
        })
        return response.data
    }catch (error) {
        console.log(error)
    }
}

export const getAllPaceSiteContacts = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/ResourcingData/GetSiteContacts`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json'
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getAllClientSiteContacts = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/ResourcingData/GetClientSiteContacts`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json'
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const addNewRegion = async (token: any, name: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/ResourcingData/AddNewRegion?region=${name}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const AddNewWorkerRoleSuggestion = async (token: any, name: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/ResourcingData/AddNewWorkRoleSuggestion?workRoleSuggestion=${name}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const AddNewSiteContacts = async (token: any, name: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/ResourcingData/AddNewSiteContact?siteContact=${name}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const AddNewClientSiteContacts = async (token: any, name: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/ResourcingData/AddNewClientSiteContact?clientSiteContact=${name}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const editRegion = async (token: any, id: any, name: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/ResourcingData/EditRegion?regionId=${id}&region=${name}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const editWorkRoleSuggestion = async (token: any, id: any, name: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/ResourcingData/EditWorkRoleSuggestion?workRoleSuggestionId=${id}&workRoleSuggestion=${name}`, id,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const editPaceSiteContact = async (token: any, id: any, name: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/ResourcingData/EditSiteContact?siteContactId=${id}&siteContact=${name}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const editClientSiteContact = async (token: any, id: any, name: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/ResourcingData/EditClientSiteContact?clientSiteContactId=${id}&clientSiteContact=${name}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const deleteRegion = async (token: any, id: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/api/ResourcingData/DeleteRegion?regionId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.log(error)
    }
}

export const deleteWorkRole = async (token: any, id: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/api/ResourcingData/DeleteWorkRoleSuggestion?workRoleSuggestionId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.log(error)
    }
}

export const deletePaceSiteContact = async (token: any, id: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/api/ResourcingData/DeleteSiteContact?siteContactId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.log(error)
    }
}

export const deleteClientSiteContact = async (token: any, id: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/api/ResourcingData/DeleteClientSiteContact?clientSiteContactId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.log(error)
    }
}

export const getPaymentMethods = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Payroll/GetPaymentMethods`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const addNewPaymentMethod = async (token: any, name: any, displayName: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/Payroll/AddNewPaymentMethod?name=${name}&displayName=${displayName}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const editPaymentMethod = async (token: any, id: any, name: any, displayName: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/api/Payroll/EditPaymentMethod?paymentMethodId=${id}&editName=${name}&displayName=${displayName}`, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const generatePayrollReport = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Payroll/GeneratePayrollReport?week=${week}&year=${year}`, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        const blob = new Blob([response.data], { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Payroll_Report_${week}_${year}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(link.href); 
        return response
    } catch (error) {
        console.error(error);
    }
}

export const getCompetencies = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetCompetencies`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const getExperiences = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetExperienceTypes`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const generateFatigueReport = async (token: any, startDate: any, endDate: any, workerId: any, workerName: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GenerateFatigueReport`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                workerId: workerId,
                workerName: workerName
            },
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        const blob = new Blob([response.data], { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${workerName} - Fatique and Risk ${format(new Date(), 'dd-MM-yyyy')}.xls`;
        link.click();
        window.URL.revokeObjectURL(link.href); 
        return response
    } catch (error) {
        console.error(error);
    }
}

export const getAllCompetenecies = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Competencies/GetCompetencies`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const addNewCompetency = async (token: any, competencyName: any) => {
    try {
        const response = await axios.post(`${apiUrl}/api/Competencies/AddNewCompetency?competencyName=${competencyName}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const editCompetency = async (token: any, competencyId: any, competencyName: any) => {
    try {
        const response = await axios.post(`${apiUrl}/api/Competencies/EditCompetency?competencyId=${competencyId}&editedCompetencyName=${competencyName}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const getAllExperiences = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Experiences/GetExperienceTypes`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const addNewExperience = async (token: any, experienceName: any) => {
    try {
        const response = await axios.post(`${apiUrl}/api/Experiences/AddNewExperienceType?experienceName=${experienceName}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const editExperience = async (token: any, experienceTypeId: any, editedExperienceName: any) => {
    try {
        const response = await axios.post(`${apiUrl}/api/Experiences/EditExperienceType?experienceTypeId=${experienceTypeId}&editedExperienceName=${editedExperienceName}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const sendPushNotifications = async (token: any, jobId: any, workers: any) => {
    try {
        const response = await axios.post(`https://api.pace-is.co.uk/Notifications/SendPushNotifications?jobId=${jobId}`, 
            workers, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response;
    }catch(error) {
        console.log(error)
    }
}

export const getDailyWorkersList = async (token: any, day: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetDailyWorkersList?day=${day}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const getWorkforceLocations = async (token: any, payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/WorkforceMap`, {
            postCode: payload.postCode,
            distance: payload.distance,
            selectedCompetencyId: payload.selectedCompetencyId,
            selectedExperienceId: payload.selectedExperienceId
        }, {

            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const getStaffDetails = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/getStaffDetails`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const getClientsFromResourceGrid = async (token: any, codeName: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetClientsFromResourceGrid?codeName=${codeName}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const addClientToResourceGrid = async (token: any, payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/AddNewClientToResourceGrid`, payload, {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }})
        return response
    } catch (error) {
        console.log(error)
    }
}

export const editClientToResourceGrid = async (token: any, payload: any) => {
    console.log(payload)
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/EditClientFromResourceGrid`, payload, {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }})
        return response
    } catch (error) {
        console.log(error)
    }
}

export const deleteClientFromResourceGrid = async (token: any, payload: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/Resourcing/DeleteClientFromResourceGrid`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: payload 
        })
        return response
    } catch(error) {
        console.log(error)
    }
}
