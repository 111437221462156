import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap, Marker, Tooltip, Circle } from "react-leaflet";
import { Button, CircularProgress, Grid, MenuItem, Select, TextField } from "@mui/material";
import { getCompetencies, getExperiences, getWorkforceLocations } from "../../../api/apiCall";
import './WorkforceMap.css';
import { useAuth } from "../../../auth/auth";
import { useForm } from "react-hook-form";
import L from "leaflet";
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const MapController = ({ coordinates, zoom }: { coordinates: [number, number], zoom: number }) => {
    const map = useMap();
    useEffect(() => {
        if (coordinates) {
            map.setView(coordinates, zoom);
        }
    }, [coordinates, map]);
    return null;
};

const customIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 37],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const WorkforceMap = () => {
    const { state } = useAuth();
    const [competencies, setCompetencies] = useState<any>([]);
    const [experiences, setExperiences] = useState<any>([]);
    const [workers, setWorkers] = useState<any>([]);
    const [location, setLocation] = useState<any>();
    const [coordinates, setCoordinates] = useState<any>([51.505, -0.09]);
    const [zoom, setZoom] = useState<number>(9);
    const [selectedWorker, setSelectedWorker] = useState<any>(null);
    const [workersLocation, setWorkersLocation] = useState<any>(false);
    const [circleRadiusMeters, setCircleRadiusMeters] = useState(0)
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, reset, watch } = useForm({
        defaultValues: {
            postCode: '',
            distance: 0,
            selectedCompetencyId: "00000000-0000-0000-0000-000000000000",
            selectedExperienceId: "00000000-0000-0000-0000-000000000000",
        }
    });

    const getAllCompetencies = async () => {
        try {
            const response = await getCompetencies(state.user?.token);
            setCompetencies(response);
        } catch (error) {
            console.log(error);
        }
    };

    const getAllExperiences = async () => {
        try {
            const response = await getExperiences(state.user?.token);
            setExperiences(response);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (data: any) => {
        setLoading(true)
        setCircleRadiusMeters(data.distance * 1000)
        const response = await getWorkforceLocations(state.user?.token, data);
        if(response) {
            setWorkers(response.workers);
            setLocation(response.location);
            setLoading(false)
        }
    };

    const getCoordinates = (latitude: any, longitude: any, worker: any) => {
        setCoordinates([latitude, longitude]);
        setZoom(14);
        setSelectedWorker(worker);
    };

    const handleCompetencyChange = (event: any) => {
        const value = event.target.value;
        reset((prev) => ({
            ...prev,
            selectedCompetencyId: value,
            selectedExperienceId: "00000000-0000-0000-0000-000000000000",
        }));
    };

    const handleExperienceChange = (event: any) => {
        const value = event.target.value;
        reset((prev) => ({
            ...prev,
            selectedExperienceId: value,
            selectedCompetencyId: "00000000-0000-0000-0000-000000000000",
        }));
    };

    useEffect(() => {
        getAllCompetencies();
        getAllExperiences();
    }, []);

    return (
        <>
            <div className="filter-div">
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <TextField
                            label="Post Code"
                            type="text"
                            size="small"
                            {...register("postCode")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <TextField
                            label="Distance in Km"
                            type="number"
                            size="small"
                            {...register("distance")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={watch("selectedCompetencyId")}
                            size="small"
                            {...register("selectedCompetencyId")}
                            onChange={handleCompetencyChange}
                            fullWidth
                        >
                            <MenuItem disabled value={"00000000-0000-0000-0000-000000000000"}>
                                Select Competency
                            </MenuItem>
                            {competencies.map((client: any) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.skillName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            size="small"
                            value={watch("selectedExperienceId")}
                            {...register("selectedExperienceId")}
                            onChange={handleExperienceChange}
                            fullWidth
                        >
                            <MenuItem disabled value={"00000000-0000-0000-0000-000000000000"}>
                                Select Experience
                            </MenuItem>
                            {experiences.map((experience: any) => (
                                <MenuItem key={experience.id} value={experience.id}>
                                    {experience.experienceName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Button onClick={handleSubmit(onSubmit)} fullWidth variant="contained">
                        {loading? <CircularProgress size={25} style={{color: 'white'}} /> : 'Filter'}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Button fullWidth variant="contained">
                            Search by Region
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <div className="main-map-div">
                <div className="map-div">
                    <MapContainer center={coordinates} zoom={zoom} style={{ height: '74vh', width: '74vw', zIndex: 1 }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {selectedWorker && (
                            <Marker position={coordinates} icon={customIcon}>
                                <Tooltip offset={[0, -30]} permanent={true} direction="top">
                                    <div style={{ backgroundColor: 'white', fontWeight: 600, textAlign: 'center'}}>{selectedWorker.fullName} <br/> {selectedWorker.pts}</div>
                                </Tooltip>
                            </Marker>
                        )}
                        {workersLocation && (
                            <>
                                {workers.map((worker: any) => (
                                    <Marker
                                        key={worker.id}
                                        icon={customIcon}
                                        position={[worker.latitude, worker.longitude]}
                                    >
                                        <Tooltip offset={[0, -30]} permanent={true} direction="top">
                                            <div style={{ backgroundColor: 'white', fontWeight: 600, textAlign: 'center'}}>{worker.fullName} <br/> {worker.pts}</div>
                                        </Tooltip>
                                    </Marker>
                                ))}
                            </>
                        )}
                        {location &&
                            <>
                                <Marker position={[location.latitude, location.longitude]} icon={customIcon}>
                                    <Tooltip offset={[0, -30]} permanent direction="top" opacity={1} className="tooltip-red">
                                        {location.postCode}
                                    </Tooltip>
                                </Marker>
                                <Circle
                                    center={[location.latitude, location.longitude]}
                                    radius={circleRadiusMeters}
                                    pathOptions={{
                                        color: '#F69E5E',
                                        fillColor: '#021332',
                                        fillOpacity: 0.2,
                                    }}
                                />
                            </>
                        }
                        <MapController coordinates={coordinates} zoom={zoom} />
                    </MapContainer>
                </div>
                <div className="list-workers">
                    <div className="container-2">
                        <h1 className="workers-title">
                            List of workers - total <span className="total">{workers.length}</span> 
                            {workers.length > 0 && 
                                <>
                                    {!workersLocation?
                                        <Button
                                            onClick={() => setWorkersLocation(!workersLocation)}
                                            style={{border: '1px solid #198bdf', margin: 5}}
                                        >
                                        Show all
                                        </Button> : 
                                        <Button onClick={()=>setWorkersLocation(!workersLocation)} style={{border: '1px solid #198bdf', margin: 5}}>Clear All</Button>
                                    }
                                </>
                            }
                        </h1>
                        <div className="workers-list">
                            {workers.map((worker: any, index: any) => (
                                <div key={index} className="worker-card" onClick={() => getCoordinates(worker.latitude, worker.longitude, worker)}>
                                    <h2 className="worker-name">{worker.fullName}</h2>
                                    <p className="worker-detail">
                                        <strong>PTS:</strong> {worker.pts}
                                    </p>
                                    <p className="worker-detail">
                                        <strong>Phone:</strong> {worker.phoneNumber}
                                    </p>
                                    <p className="worker-detail">
                                        <strong>Post Code:</strong> {worker.postCode}
                                    </p>
                                    <p className="worker-details-link">
                                        <a href="/pace-team/resourcing/regions-map">Details</a>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkforceMap;
