/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import {
    addNewRegion,
    deleteRegion,
    editRegion,
    GetAllRegions
} from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Modal } from "antd";

const Regions = () => {
    const {state} = useAuth();
    const [regions, setRegions] = useState<any>([]);
    const [, setOpen] = useState(false);

    const handleAddRow = () => {
        const newRow = {
            id: regions.length + 1,
            name: ``
        };
        setRegions((prevRows: any) => [newRow, ...prevRows]);
    };

    const handleChange = (id: any, field: any, newValue: any) => {
        const updatedRows = regions.map((row: any) =>
          row.id === id ? { ...row, [field]: newValue } : row
        );
        setRegions(updatedRows);
    };


    const columns: any = [
        {
            field: "name",
            headerName: "Regions",
            flex: 1,
            renderCell: (params: any) => (
                <TextField
                    size="small"
                    value={params.row.name}
                    fullWidth
                    style={{ height: "5px", margin: 5 }}
                    onChange={(event: any) => {
                        handleChange(params.id, "name", event.target.value);
                    }}
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                            if (event.key === ' ') {
                                event.stopPropagation();
                            }
                        },
                    }}
                />
              ),
        },
        {
            field: "addedDate",
            headerName: "Added Date",
            flex: 0.3,
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.addedDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "save",
            headerName: "Save",
            flex: 0.3,
            renderCell: (params: any) => (
                <Button variant="outlined" onClick={()=> handleCellEditCommit(params)}>Save</Button>
            )
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 0.3,
            renderCell: (params: any) => (
                <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => {
                        setOpen(true);
                        Modal.confirm({
                            title: 'Are you sure you want to delete this region?',
                            onOk: () => handleDelete(params.id),
                            okButtonProps: { danger: true },
                            okText: 'YES',
                            cancelText: 'NO',
                            centered: true
                        });
                    }}
                >
                    Delete
                </Button>
            )
        }
    ];

    const handleCellEditCommit = async (params: any) => {
        if(typeof params.row.id === 'number') {
            const response = await addNewRegion(state.user?.token, params.row.name)
            if(response) {
                toast.success(response.data);
                GetAllRegions(state.user?.token).then(setRegions);
            } else {
                toast.error('Something went wrong!')
            }
        } else {
            const response = await editRegion(state.user?.token, params.row.id, params.row.name)
            if(response) {
                toast.success(response.data);
            } else {
                toast.error('Something went wrong!')
            }
        }
    };

    const handleDelete = async (id: any) => {
        const response = await deleteRegion(state.user?.token, id);
        if(response) {
            toast.success(response.data);
            GetAllRegions(state.user?.token).then(setRegions);
        } else {
            toast.error('Something went wrong!')
        }
    }

    const Toolbar = () => {
        return (
            <div className="header-div">
                <GridToolbarFilterButton />
                <div className="header-div-title">Regions</div>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddRow}
                    style={{ marginRight: '1%',borderColor: "#ffa970",
                    backgroundColor: "white", color: "#ffa970"}}
                >
                    Add Region
                </Button>
            </div>
        );
    };

    useEffect(() => {
        GetAllRegions(state.user?.token).then(setRegions);
    }, [])

    return (
        <Box 
            sx={{
                width: '100%',
                height: '550px',
                "@media (max-width: 900px)": {
                    overflowX: "auto",
                    margin: 1,
                    minWidth: "95%",
                },
            }}>
            <DataGrid
                rows={regions}
                columns={columns}
                slots={{ toolbar: Toolbar }}
                disableRowSelectionOnClick
                sx={{
                    "@media (max-width: 900px)": {
                        width: "100%",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#ffa970",
                        color: "#000",
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .disable-columns": {
                        backgroundColor: "#f5f5f5",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "0.7rem",
                    },
                }}
            />
        </Box>
    );
};

export default Regions;
