import './resource.css'
import React, { useEffect, useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {Dropdown} from 'antd';
import {Button} from '@mui/material';
import Shift from '../../components/Shift/Shift';
import html2canvas from 'html2canvas';
import { format } from 'date-fns';
import Modal from 'react-modal';
import Clients from '../Modal/Clients/AddClients';
import { useSearchParams } from 'react-router-dom';
import { getClientsFromResourceGrid } from '../../../../api/apiCall';
import { useAuth } from '../../../../auth/auth';

const ResourceTracker = ({
  days,
  gridData,
  handleCellClick,
  handleDragStart,
  handleDrop,
  handleDragOver,
  items,
  handleMenuClick,
  handleRightClick,
  dragStatus,
  handleFlip,
  flippedCells,
  length,
  setLength,
  duplicateMode,
  disabledCells,
  connection,
  isConnected
}: any) => {
  const {state} = useAuth();
  const [open, setOpen] = useState(false)
  const [clients, setClients] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hide, setHide] = useState(false)

  const addNewRow = () => {
    setLength((prevLength: any) => {
      const newLength: any = prevLength + 1;
      return newLength;
    });
  };

  const captureRef = React.useRef(null);

  const takeScreenshot = () => {
    const element: any = captureRef.current;

    html2canvas(element, {
      scale: 2,
      useCORS: true
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'screenshot.png';
      link.click();
    });
  };

  const reorderedDays = [...days.slice(-1), ...days.slice(0, -1)];
  const getClients = async () => {
    const response = await getClientsFromResourceGrid(state.user?.token, `${searchParams.get('week')}-${searchParams.get('year')}`)
    setClients(response)
  }

  const openModalforClients = () => {
    setOpen(true);
  }

  useEffect(() => {
    getClients();
  }, [searchParams]);

  if(connection) {
    connection.on('UpdateClientsFromGrid', () => {
      getClients();
    })
  }

  return (
    <>
      <div className="grid-container" ref={captureRef}>
        {reorderedDays.map((day: any, index: any) => (
          <div
            key={day.day}
            className={`grid-header ${index === 0 && hide && 'sticky-client'}`}
            onClick={index === 0 ? () => openModalforClients() : undefined}
            style={{
              backgroundColor: duplicateMode ? '#afd09e' : '#ccf1b8',
              cursor: index === 0 ? 'pointer': '',
              zIndex: index===0 && hide ? 2:1,
            }}
          >
            {index === 0 ? "Clients" : format(day.day, 'EEEE, dd MMMM yyyy')}
          </div>
        ))}
        {Array.from({ length: length }).map((_, rowIndex) =>
          reorderedDays.map((day: any, shiftedColIndex: number) => {
            const colIndex = (shiftedColIndex + 7) % days.length;
            const clientInfo = clients.find(
              (client: any) => rowIndex >= client.startRow -1 && rowIndex <= client.endRow -1
            );
            return (
              <Dropdown
                menu={{
                  items,
                  onClick: handleMenuClick,
                }}
                trigger={[gridData[`${colIndex}-${rowIndex}`] && "contextMenu"]}
              >
                <div
                  key={`${colIndex}-${rowIndex}`}
                  className={`grid-item ${
                    dragStatus[`${colIndex}-${rowIndex}`] === "success"
                      ? "success-style"
                      : dragStatus[`${colIndex}-${rowIndex}`] === "failed"
                      ? "failed-style"
                      : ""
                  } ${colIndex === 7 && hide && "sticky-client"}
                  ${colIndex === 7 && "client-info"}`}
                  onDrop={(event) => handleDrop(colIndex, rowIndex, event, day.day)}
                  onDragOver={handleDragOver}
                  onClick={() => handleCellClick(colIndex, rowIndex, day.date, day.day)}
                  onContextMenu={(event) =>
                    handleRightClick(colIndex, rowIndex, event)
                  }
                  style={{ 
                    cursor: duplicateMode ? 'grab' : 'default', 
                    backgroundColor: disabledCells.some((cells: any) => 
                      colIndex === cells.colIndex && rowIndex === cells.rowIndex
                    ) ? '#f5f5f5' : '',
                    border: disabledCells.some((cells: any) => 
                      colIndex === cells.colIndex && rowIndex === cells.rowIndex
                    ) ? '2px solid #A2C4C9' : '',
                    ...(shiftedColIndex === 0 && { backgroundColor: clientInfo?.color })      
                  }}
                >
                  {!gridData[`${colIndex}-${rowIndex}`] && (
                    <div style={{ textAlign: "right" }}>
                      {colIndex === 7 ? (
                        rowIndex === clientInfo?.startRow - 1 ? (
                          <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 20 }}>
                            {clientInfo ? `${clientInfo.clientName}` : ''}
                          </div>
                        ) : (null)
                      ) : (
                        `${rowIndex + 1}, ${colIndex + 1}`
                      )}
                    </div>
                  )}
                  {gridData[`${colIndex}-${rowIndex}`] && (
                    <>
                      <div
                        draggable
                        onDragStart={(event) =>
                          handleDragStart(colIndex, rowIndex, event)
                        }
                        onClick={() => handleFlip(colIndex, rowIndex)}
                      >
                        <Shift
                          gridData={gridData}
                          colIndex={colIndex}
                          rowIndex={rowIndex}
                          day={day}
                          flippedCells={flippedCells}
                        /> 
                      </div>
                    </>
                  )}
                </div>
              </Dropdown>
            );
          })
        )}
        <div className='button'>
          <Button
            variant="outlined"
            startIcon={
              <FontAwesomeIcon
                icon={faPlus}
                size={'5x'}
                color='#e39865'
              />
            }
            onClick={addNewRow}
            size='large'
            style={{
              width: "10px",
              borderColor: 'black',
              color: 'black'
            }}>
          </Button>
        </div>
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => {setOpen(false); getClients()}}
        className='add-modal'
        style={{
          overlay: { zIndex: 2 }
        }}
      >
        <Clients
          connection={connection}
          isConnected={isConnected}
          hide={hide}
          setHide={setHide}
        />
      </Modal>
    </>
  );
};

export default ResourceTracker
