/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useAuth } from '../../auth/auth';
import { removeUser } from '../../auth/actions';
import './Header.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const {state, dispatch} = useAuth();
  const [avatarColor, setAvatarColor] = useState<any>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElStaff, setAnchorElStaff] = React.useState(null);
  const [anchorElRTdata, setAnchorElRTData] = React.useState(null);
  const [anchorElSentinel, setAnchorElSentinel] = React.useState(null);
  const [anchorElonCall, setAnchorElonCall] = React.useState(null);
  const [anchorElMore, setAnchorElMore] = React.useState(null);
  const open = Boolean(anchorEl);
  const openStaff = Boolean(anchorElStaff);
  const openRtData = Boolean(anchorElRTdata);
  const openSentinel = Boolean(anchorElSentinel);
  const openOnCall = Boolean(anchorElonCall);
  const openMore = Boolean(anchorElMore);

  const colors = [
    '#444c5c', '#ce5a57', '#78a5a3', '#e1b16a', '#aaaaaa',
    '#efc070', '#A2C4C9', '#8E7CC3', '#D5A6BD', '#EA9999'
  ];

  const getInitials = () => {
    return `${state.user?.name?.[0]}${state.user?.surname?.[0]}`;
  };

  const openAvatar = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickStaff = (event: any) => {
    setAnchorElStaff(event.currentTarget);
  };

  const handleClickRTData = (event: any) => {
    setAnchorElRTData(event.currentTarget);
  };

  const handleClickSentinel = (event: any) => {
    setAnchorElSentinel(event.currentTarget);
  };

  const handleClickOnCall = (event: any) => {
    setAnchorElonCall(event.currentTarget);
  };

  const handleClickMore = (event: any) => {
    setAnchorElMore(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseStaff = () => {
    setAnchorElStaff(null);
  };

  const handleCloseRtData = () => {
    setAnchorElRTData(null);
  };

  const handleCloseSentinel = () => {
    setAnchorElSentinel(null);
  };

  const handleCloseOnCall = () => {
    setAnchorElonCall(null);
  };

  const handleCloseMore = () => {
    setAnchorElMore(null);
  };

  const logOut = () => {
    dispatch(removeUser())
    localStorage.removeItem('avatarColor')
  }

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  useEffect(() => {
    let storedColor = localStorage.getItem('avatarColor');
    if (!storedColor) {
      storedColor = getRandomColor();
      localStorage.setItem('avatarColor', storedColor);
    }
    setAvatarColor(storedColor);
  }, []);

  return (
    <div className='main-header'>
      <Link to={'/pace-team/resourcing/resource-tracker'} className='logo'>PACE</Link>
      <div>
        <div className='link' style={{textDecoration: 'none'}}>
          <>
            <Link to={`/pace-team/resourcing/resource-tracker`} className={`rt`}>Resource Tracker</Link>
            <div
              className='rt'
              onClick={handleClickStaff}
            >
              Staff <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorElStaff}
              open={openStaff}
              onClose={handleCloseStaff}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem><Link to={`/pace-team/resourcing/staff-details`} className='dropdown' onClick={handleCloseStaff}>Staff Details</Link></MenuItem>
              <MenuItem><Link to={`/pace-team/workers-by-date`} className='dropdown' onClick={handleCloseStaff}>Workers By Date</Link></MenuItem>
              <MenuItem><Link to={`/pace-team/resourcing/workforce-map`} className='dropdown' onClick={handleCloseStaff}>Workforce Map</Link></MenuItem>
            </Menu>
            <div
              className='rt'
              onClick={handleClickRTData}
            >
              RT Data <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorElRTdata}
              open={openRtData}
              onClose={handleCloseRtData}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem><Link to={`/pace-team/collaborations/clients`} className='dropdown' onClick={handleCloseRtData}>Clients</Link></MenuItem>
              <MenuItem><Link to={`/pace-team/collaborations/projects`} className='dropdown' onClick={handleCloseRtData}>Projects</Link></MenuItem>
              <MenuItem><Link to={`/pace-team/rt-data`} className='dropdown' onClick={handleCloseRtData}>Other Data</Link></MenuItem>
            </Menu>
            <div
              className='rt'
              onClick={handleClickSentinel}
            >
              Sentinel <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorElSentinel}
              open={openSentinel}
              onClose={handleCloseSentinel}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem><Link to={`/pace-team/fatigue`} className='dropdown' onClick={handleCloseSentinel}>Fatigues</Link></MenuItem>
              <MenuItem><Link to={`/pace-team/roles/manage-roles`} className='dropdown' onClick={handleCloseSentinel}>Roles</Link></MenuItem>
              <MenuItem><Link to={`/pace-team/competencies`} className='dropdown' onClick={handleCloseSentinel}>Competencies</Link></MenuItem>
              <MenuItem><Link to={`/pace-team/experiences`} className='dropdown' onClick={handleCloseSentinel}>Experienes</Link></MenuItem>
              <MenuItem><Link to={`/`} className='dropdown' onClick={handleCloseSentinel}>Tickets</Link></MenuItem>
            </Menu>
            <div
              className='rt'
              onClick={handleClickOnCall}
            >
              On Call <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorElonCall}
              open={openOnCall}
              onClose={handleCloseOnCall}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem><Link to={`/pace-team/resourcing/live-tracker`} className='dropdown' onClick={handleCloseOnCall}>Live Tracker</Link></MenuItem>
            </Menu>
            <div
              className='rt'
              onClick={handleClickMore}
            >
              Payroll <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorElMore}
              open={openMore}
              onClose={handleCloseMore}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem><Link to={`/pace-team/payroll/payment-method`} className='dropdown' onClick={handleCloseMore}>Payment Methods</Link></MenuItem>
            </Menu>
            <Tooltip title="">
              <IconButton onClick={openAvatar}>
                <Avatar style={{fontSize: 16, backgroundColor: avatarColor}} sx={{height: 35, width: 35}}>
                  {getInitials()}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem
                sx={{width: 200}} 
                onClick={() => { handleClose(); logOut(); }}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        </div>
      </div>
    </div>
  );
};

export default Header;