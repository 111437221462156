import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './auth/auth';
import 'leaflet/dist/leaflet.css';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
const myTheme = createTheme({
  // Set up your custom MUI theme here
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={myTheme}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
