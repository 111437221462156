import React from 'react';
import {  useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import './Layout.css'

const Layout = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();

    return (
        <>
            {!location.pathname.includes('/resource-tracker') && <Header />}
            <div className='layout'>
                <div style={{flexGrow: 1}}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Layout;