/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import { getDailyWorkersList } from '../../../../../api/apiCall';
import { useAuth } from '../../../../../auth/auth';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import '../Workers.css'

export default function WorkersByDate() {
  const {state} = useAuth();
  const [workersList, setWorkersList] = useState<any>([]);
  const [value, setValue]: any = React.useState(dayjs());

  const getWorkersList = async () => {
    try {
        const response = await getDailyWorkersList(state.user?.token, format(value.$d, 'yyyy-MM-dd'));
        setWorkersList(response?.data)
    } catch (error) {
        console.log(error)
    }
  }
  
  const columns = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 300,
    },
    {
      field: 'pts',
      headerName: 'PTS',
      width: 300,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 300,
    },
    {
        field: 'location',
        headerName: 'Location',
        width: 300,
    },
    {
        field: 'jobDate',
        headerName: 'Job Date',
        width: 300,
        renderCell: (params: any) => (
            <div>
                {format(new Date(params.row.jobDate), 'dd/MM/yyyy')}
            </div>
        ),
    },
  ];

  const Toolbar = () => {
    return (
        <div>
            <GridToolbarFilterButton />
        </div>
    );
  };

  useEffect(() => {
        getWorkersList();
  }, [value]);

  return (
    <>
      <div style={{margin: 20}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Pick a date"
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
        </LocalizationProvider>
      </div>
      <Box sx={{ height: "750px", width: '80%' }} marginTop={1} marginLeft={25}>
        <DataGrid
          rows={workersList}
          columns={columns}
          disableRowSelectionOnClick
          slots={{ toolbar: Toolbar }}
          getRowId={(row: any) => `${row.fullName}-${row.phoneNumber}`}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14,
              },
            },
          }}
          pageSizeOptions={[14]}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#ffa970",
              color: "#000",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            '& .disable-columns': {
              backgroundColor: '#f5f5f5',
            },
          }}
        />
      </Box>
    </>
  );
}
