/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import workerIcon from '../../../../assets/worker-icon.png'
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { Box } from "@mui/material";
import './OnCall.css'
import { useSearchParams } from "react-router-dom";
import { getWeeklyOnCallDetails } from "../../../../api/apiCall";
import { useAuth } from "../../../../auth/auth";
import { format } from "date-fns";
import { Progress } from "antd";

const OnCall = () => {
  const {state} = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState()
  const [week, setWeek] = useState<any>(searchParams.get('week') || 1);
  const [year, setYear] = useState<any>(searchParams.get('year') || 2024);
  const [onCallDetails, setOnCallDetails] = useState<any>([]);

  const getOnCallDetails = async () => {
    try {
      const response = await getWeeklyOnCallDetails(state.user?.token, week, year);
      setOnCallDetails(response);
    } catch (error) {
      console.log(error)
    }
  }
 
  const getProgressColor = (percent: any) => {
    if (percent > 0 && percent <= 30) {
      return 'red';
    } else if (percent > 30 && percent <= 60) {
      return 'orange';
    } else if (percent > 60 && percent <= 89) {
      return '#1677ff';
    } else if (percent <= 100) {
      return 'green';
    }
  }

  const columns = [
    // {
    //   field: "",
    //   headerName: "",
    //   width: 150,
    //   cellClassName: 'day-bold',
    //   valueGetter: (params: any, row: any) => ''
    // },
    {
      field: "days",
      headerName: "Day",
      width: 150,
      cellClassName: 'day-bold',
      valueGetter: (params: any, row: any) => format(row.day, 'EEEE')
    },
    {
      field: "dates",
      headerName: "Date",
      width: 150,
      
      valueGetter: (params: any, row: any) => format(row.day, 'dd/MM/yyyy')
    },
    {
      field: "plannedWorkers",
      headerName: "Planned Workers",
      width: 150,
    },
    {
      field: "actualWorkers",
      headerName: "Actual Workers",
      width: 150,
    },
    {
      field: "percentage",
      headerName: "Percentage",
      width: 250,
      valueGetter: (params: any, row: any) => Number(row.percentage.toFixed(1)),
      renderCell:(params: any) => (
        <Progress
          percent={params.value}
          strokeColor={getProgressColor(params.value)}
          style={{width: '200px'}}
          status="normal"
        />
      )
    },
  ];

  const sumOfActualWorkers = onCallDetails.reduce((acc: any, item: any) => acc + item.actualWorkers, 0);
  const sumOfPlannedWorkers = onCallDetails.reduce((acc: any, item: any) => acc + item.plannedWorkers, 0);
  const attendancePercentage = (sumOfActualWorkers / sumOfPlannedWorkers) * 100;

  useEffect(() => {
    setSearchParams({ week, year })
    getOnCallDetails()
  }, [date])

  return (
    <>
      <Header
        setDate={setDate}
        week={week}
        setWeek={setWeek}
        year={year}
        setYear={setYear}
      />
      <div className="on-call">
        <div className="planned-workers">
          <h3 className="planned-workers-text">Planned Workers </h3>
          <div className="worker-stats">
            <h1>{sumOfPlannedWorkers}</h1>
            <img src={workerIcon} width={80} height={80} alt={"worker-icon"} />
          </div>
        </div>
        {/* <div className="planned-workers shifts">
          <h3 className="planned-workers-text">All Shifts per week </h3>
          <div className="worker-stats">
            <h1>{sumOfPlannedWorkers}</h1>
            <img src={workerIcon} width={80} height={80} />
          </div>
        </div> */}
        <div className="planned-workers actual-workers">
          <h3 className="planned-workers-text">Actual Workers</h3>
          <div className="worker-stats">
            <h1>{sumOfActualWorkers}</h1>
            <h1 className="percentage">{attendancePercentage.toFixed(1)}%</h1>
          </div>
        </div>
      </div>
      <div className="table">
        <Box sx={{height: '100%', width: '100%'}} marginTop={3}>
          <DataGrid
            rows={onCallDetails}
            columns={columns}
            getRowId={(row: any) => row.day}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 14,
                }, 
              }, 
            }}
            pageSizeOptions={[14]}
            sx={{
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#fff',
                color: '#000',
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#fff',
              },
              '& .MuiDataGrid-row': {
                '&:nth-of-type(odd)': {
                  backgroundColor: '#fff3eb',
                },
              },
              '& .highlighted-column': {
                fontWeight: 'bold',
              },
              '& .day-bold': {
                fontWeight: 'bold',
              },
            }}
          />
        </Box>
      </div>
    </>
  )
}

export default OnCall;