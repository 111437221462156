import React from "react";
import {TextField, Button} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {loginUser} from "../../api/apiCall";
import { setUser} from "../../auth/actions";
import {useAuth} from "../../auth/auth";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import paceLogo from '../../assets//paceLogo.jpg';
import './login.css';

type FormValues = {
    username: string
    password: string
}

const Login = () => {
    const navigate = useNavigate();
    const {dispatch} = useAuth();
    const {register, handleSubmit, formState: {errors}} = useForm<FormValues>()

    const userLogin = async (data: any) => {
        const response = await loginUser(data);
        if(response?.data) {
            dispatch(
                setUser({
                    id: response.data.id,
                    name: response.data.name,
                    surname: response.data.surname,
                    emal: response.data.email,
                    username: response.data.username,
                    token: response.data.accessToken,
                })
            )
            navigate('/pace-team/resourcing/resource-tracker')
        }else {
            toast.error(response)
        }
    }

    return (
        <>
            <div className="container">
                <div className="background-image" />
                <div className="login-form">
                    <img src={paceLogo} width={150} height={150} alt={"pace-logo"} />
                    <h2>Sign in to your account</h2>
                    <TextField
                        label="Username"
                        type="text"
                        size="medium"
                        color="warning"
                        {...register("username", {
                            required: "Username is required"
                        })}
                        error={!!errors.username}
                        helperText={errors.username?.message}
                        style={{ margin: '15px', width: '70%', backgroundColor: 'white'}}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        size="medium"
                        color="warning"
                        {...register("password", {
                            required: "Password is required"
                        })}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        style={{ margin: '15px', width: '70%', backgroundColor: 'white', borderColor: '#021332'}}
                    />
                    <Button
                        variant="outlined"
                        onClick={handleSubmit(userLogin)}
                        size="large"
                        className="button-mui"
                        style={{ backgroundColor: "#021332", color: 'white', borderColor: '#021332', margin: 40 }}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Login;
