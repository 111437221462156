import { useEffect, useState } from "react";
import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { useAuth } from "../auth/auth";
import { toast } from "react-toastify";

const useSignalRConnection = () => {
    const [connection, setConnection] = useState<any>(null);
    const [isConnected, setIsConnected] = useState(false);
    const { state } = useAuth();

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('https://railresourcing.api.pace-is.co.uk/MainHub', {
                accessTokenFactory: () => state.user?.token ? state.user.token : '',
                withCredentials: true,
            })
            .withAutomaticReconnect([0, 2000, 10000, 30000])
            .configureLogging(LogLevel.Information)
            .build();

        const startConnection = async () => {
            try {
                await newConnection.start();
                console.log("SignalR Connected");
                toast.success('Online', {
                    position: "bottom-right"
                })
                setConnection(newConnection);
                setIsConnected(true)
            } catch (err) {
                console.log("Error connecting to SignalR", err);
                toast.error('Try refresh the page')
                setIsConnected(false)
                setTimeout(startConnection, 2000);
            }
        };

        newConnection.onclose(async (error) => {
            setConnection(null)
            setIsConnected(false)
            console.error("Connection closed unexpectedly", error);
            if (error) {
                console.log("Attempting to reconnect...");
                setTimeout(startConnection, 2000);
            }
        });

        newConnection.onreconnecting((error) => {
            toast.error('Reconnecting, wait a second', {
                position: "bottom-right"
            })
            setIsConnected(false)
            console.warn("Connection lost. Reconnecting...", error);
        });

        newConnection.onreconnected((connectionId) => {
            setConnection(newConnection)
            setIsConnected(true)
            toast.info('Reconnected', {
                position: "bottom-right"
            })
            console.log("SignalR reconnected. Connection ID:", connectionId);
        });

        newConnection.serverTimeoutInMilliseconds = 60000;

        startConnection();

        return () => {
            if (newConnection.state === HubConnectionState.Connected) {
                newConnection.stop();
                console.log("SignalR Disconnected");
            }
        };
    }, [state.user?.token]);

    return {connection, isConnected};
};

export default useSignalRConnection;
